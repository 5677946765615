window.addEventListener('load', (event) => {

    const button = document.querySelector(".menu-button")
    const body = document.querySelector("body")
    const header = document.querySelector("header")

    button.onclick = function toggleMenu() {
        if (header.matches('.show')) {
            
            setTimeout(function() {
                
                header.classList.remove('display');
            }, 1000);
            
            header.classList.remove('show');
            button.classList.remove("menu-close");
            
      
        }
        else {
            button.classList.add("menu-close");
            header.classList.add('display');

            setTimeout(function() {
                header.classList.add('show');
            }, 300);
           
        }

        

    };


});